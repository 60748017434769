
import React, { useContext} from "react";
import { Box, IconButton, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CheckoutContext } from "../CheckoutModal";


function CheckoutProducts() {
    const { checkoutProducts, changeQuantityHandler } = useContext(CheckoutContext);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "10px",
            }}
        >
            {
                checkoutProducts.map((product, index) => (
                    product &&
                    <ProductCard setQuantity={(value) => { changeQuantityHandler(value, index) }} key={index} product={product} />
                ))
            }
        </Box>
    );
}

export default CheckoutProducts;




const ProductCard = ({ mode, product, setQuantity }) => {

    const { setPreviewProduct } = useContext(CheckoutContext);


    return (
        <Box
            sx={{
                border: "1px solid rgba(255, 255, 255, 0.46);",
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
                borderRadius: "7px",
                gap: "20px",
                background: mode === "add" && "#141313",
            }}
        >
            <Box
                onClick={() => { setPreviewProduct(product) }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: "10px"
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf: "flex-start",
                        gap: "10px"
                    }}
                >
                    <Box
                        component='img'
                        src={product.default_image}
                        sx={{
                            width: "48px"
                        }}
                    />
                    <Typography
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                        }}
                    > {product.name}</Typography>
                </Box>

                <Box 
                    dangerouslySetInnerHTML={{ __html: product.description }}
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        fontSize: "0.65rem"
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: "5px",
                }}
            >
                <Typography
                    sx={{
                        width: "100px",
                        textAlign: "center",
                    }}
                > {Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: product.price_details.currency,
                }).format(product.price_details.retail_price)}</Typography>


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}

                >
                    <CustomButton
                        size="small"
                        onClick={() => {
                            setQuantity(-1)
                        }}
                    >
                        <RemoveIcon sx={{
                            width: "15px"
                        }} />
                    </CustomButton>

                    <Typography
                        sx={{
                            border: "1px solid rgba(255, 255, 255, 0.46);",
                            padding: "0px 10px",
                            borderRadius: "7px",
                            fontSize: "0.7rem",
                            textAlign: "center",
                            width: "10px",
                        }}

                    >{product.quantity || 1}</Typography>
                    <CustomButton

                        onClick={() => {
                            setQuantity(+1)
                        }}
                    >
                        <AddIcon sx={{
                            width: "15px"
                        }} />
                    </CustomButton>
                </Box>

            </Box>

        </Box>
    )

}


const CustomButton = styled(IconButton)((props) => ({
    color: "white",
}));