
import React, { useEffect, useRef, useContext } from "react";
import { Box, IconButton, } from '@mui/material';
import { AppContext } from "../../AppContext";
import ThreeJSModule from "../ModelViewer_3MeraModules/ThreeJSModule";
import LoadingComp from "./LoadComp";
import ViewInArIcon from "@mui/icons-material/ViewInAr";


const threeMap = new Map()

const ModelViewer_3Mera = ({ starAR }) => {
    const { modeRef, setMode, productData, setProductData, loading, setLoading, setThreeJSModule } = useContext(AppContext);
    const canvasContainerRef = useRef()
    const canvasRef = useRef()


    const onPartSelect = (name) => {
        if (modeRef.current === "parts") {
            const selectItem = productData?.allProducts.find(product => name.includes(product.upc_number))
            if (selectItem) {
                setProductData((prev) => {
                    return {
                        ...prev,
                        displayedProduct: {
                            ...prev.displayedProduct,
                            accessoryProduct: selectItem,
                        }
                    }
                })
            }
        }
    }

    useEffect(() => {
        let appData = productData?.displayedProduct?.mainProduct || productData?.defaultProduct
        // console.log(threeMap)
        if (!threeMap.get("three")) {
            setMode("tutorial")

            const _threeJSModule = new ThreeJSModule({
                appData,
                setLoading,
                onPartSelect,
                modeRef,
                productData,
                canvasContainer: canvasContainerRef.current,
                canvas: canvasRef.current,
            })
            _threeJSModule.loadAssets(appData)
            threeMap.set("three", _threeJSModule)
            setThreeJSModule(_threeJSModule)
        } else {
            setMode("tutorial")
            const module = threeMap.get("three")
            module.loadAssets(appData)
        }

        return () => {
            const module = threeMap.get("three")
            if (module) {
                module.stop()
                module.clear()
                threeMap.delete("three")
            }
        };
    }, [productData.displayedProduct.mainProduct, productData.defaultProduct, setThreeJSModule, setLoading])

    const handleMouseDown = (e) => {
        canvasRef.current.focus();
        e.preventDefault();
    };

    const handleMouseUp = (e) => {
        canvasRef.current.focus();
        e.preventDefault();
    };

    return (
        <Box
            ref={canvasContainerRef}
            sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                cursor: "grab",
            }}
        >
            <Box
                ref={canvasRef}
                component='canvas'
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                sx={{
                    // width: "100%",
                    // height: "100%",
                    position: "absolute",
                    top: "0",
                    left: { xs: "0", md: "10px" },
                    cursor: "grab",
                }}

            >

            </Box>
            {loading !== "done" &&
                <LoadingComp loading={loading} />
            }

            <IconButton
                onClick={starAR}
                sx={{
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                    margin: "20px",
                    color: "white",
                    zIndex: "100"
                }}
            >
                <ViewInArIcon />
            </IconButton>

        </Box>
    );
}
export default ModelViewer_3Mera;