
import React, { useContext } from "react";
import { Box, Typography } from '@mui/material';
import AppVersion from "../../../../package.json";
import { AppContext } from "../../../AppContext";

function TabFooter({ sx }) {

    const { setPrivacyMode } = useContext(AppContext)

    return (
        <Box
            sx={{
                bottom: "-200px",
                marginTop: { xs: "35px", md: "25px" },
                left: "0",
                zIndex: "400",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: { xs: "10px", md: "2px" },
                width: "100%",
                borderRadius: "20px",
                ...sx,
            }}
        >
            <Typography
                onClick={() => {
                    setPrivacyMode("privacy-policy")
                }}
                sx={{
                    color: "#ffffff",
                    fontWeight: "500",
                    fontSize: "14px",
                    cursor: "pointer",
                }}
            >
                Privacy Policy
            </Typography>
            <Typography
                onClick={() => {
                    setPrivacyMode("term-and-conditions")
                }}
                sx={{
                    color: "#ffffff",
                    fontWeight: "500",
                    fontSize: "14px",
                    cursor: "pointer",
                }}
            >
                Terms & Conditions
            </Typography>
            <Typography
                onClick={() => {
                    setPrivacyMode("term-of-use")
                }}
                sx={{
                    color: "#ffffff",
                    fontWeight: "500",
                    fontSize: "14px",
                    cursor: "pointer",
                }}
            >
                Terms of Use
            </Typography>



            <Box
                onClick={() => {
                    setPrivacyMode("brand");
                }}
                component='img'
                src={"/assets/images/3mera-logo.svg"}
                sx={{

                    padding: { xs: "20px 0", md: "10px 0" },
                    fontSize: "14px",
                    maxWidth: "150px",
                    cursor: "pointer",

                }}
            >

            </Box>

            <Typography

                sx={{
                    color: "white",
                    fontWeight: "300",
                    fontSize: "14px",
                    cursor: "pointer",
                }}
            >
                {AppVersion.version}
            </Typography>
            <Box
                sx={{
                    height: "400px"
                }}
            />

        </Box>
    );
}

export default TabFooter;

