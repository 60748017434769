'use client';
import { useState } from 'react';
import { Box, Modal, Backdrop, Fade, IconButton } from '@mui/material';
import TabFooter from "../TabFooter";
import CloseIcon from '@mui/icons-material/Close';

const Video = ({ mediaData }) => {
    const [open, setOpen] = useState(false);
    const videoData = mediaData?.videos?.[0]; // get the first video from the array for now

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                position: "relative",
                padding: { xs: "10px 0", sm: "10px 0" }
            }}
        >
            <Box
                component='video'
                src={videoData?.public_url} // Assuming you have a thumbnail for the video
                alt="video thumbnail"
                onClick={handleOpen}
                sx={{
                    width: "100%",
                    maxHeight: "325px",
                    cursor: "pointer"
                }}
            />

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '90%', sm: '80%', md: '70%' },
                            // linear-gradient(180deg, #33393E 0%, #181B1D 100%)
                            background: 'linear-gradient(180deg, #33393E 0%, #181B1D 100%)',
                            boxShadow: 24,
                            p: 4,
                            outline: 0,
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box
                            component='video'
                            controls
                            src={videoData?.public_url}
                            sx={{
                                width: '100%',
                                maxHeight: '80vh',
                            }}
                        />
                    </Box>
                </Fade>
            </Modal>

            <Box
                sx={{
                    marginTop: "40px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px"
                }}
            >
            </Box>

            <TabFooter />
            <Box />
        </Box>
    );
}

export default Video;
