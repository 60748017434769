
import React, { useContext } from "react";
import { CheckoutContext } from "../CheckoutModal";
import ProductModal from "../../Modal/ProductModal";

function ProductCheckout({ onAddProduct }) {

    const { previewProduct, setPreviewProduct } = useContext(CheckoutContext);
    // const addProductHandler = () => {
    //     onAddProduct(previewProduct)
    //     setPreviewProduct(null)
    // }

    return (
        <ProductModal product={previewProduct} handleClose={() => { setPreviewProduct(null) }} setCheckoutProduct={setPreviewProduct} />
    );
}

export default ProductCheckout;




