
import React, { useContext } from "react";
import { Box, Typography } from '@mui/material';
import { CheckoutContext } from "../CheckoutModal";
import { getTotalPrice } from "./getTotalPrice";

function CheckoutTotalPrice() {

    const { checkoutProducts } = useContext(CheckoutContext);

    return (
        <Box
            sx={{
                top: "0",
                left: "0",
                zIndex: "1000",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
            }}
        >

            <Box
                sx={{
                    width: "100%",
                    marginBottom: "40px"
                }}
            >
                <Typography>Payment to Throne Toools Corporation</Typography>
                <Typography sx={{
                    fontSize: "2rem"
                }}>{getTotalPrice(checkoutProducts)}</Typography>
            </Box>

        </Box>
    );
}

export default CheckoutTotalPrice;


