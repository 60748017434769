import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import GenerateBlowOutAnimation from './GenerateBlowOutAnimation';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

import HotSpots from './HotSpots';
import WebXRModule from './WebXRModule';
import Reference from './Reference'
import { gsap } from "gsap";
import HandHint from './HandHint';

class ThreeJSModule {

    constructor(data) {
        this.appData = data.appData;
        this.onPartSelect = data.onPartSelect;
        this.src = this.appData.model_file;
        this.canvasContainer = data.canvasContainer;
        this.canvas = data.canvas;
        this.setLoading = data.setLoading;
        this.modeRef = data.modeRef;
        this.productData = data.productData;
        this.renderer = null;
        this.scene = new THREE.Scene();
        this.camera = null;
        this.arScene = new THREE.Group();
        this.scene.add(this.arScene);
        this.animationFrame = null;
        this.controls = null;
        this.gltfLoader = new GLTFLoader();
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('/assets/draco/');
        this.gltfLoader.setDRACOLoader(this.dracoLoader);
        this.hdrLoader = new RGBELoader();
        this.reference = new Reference();
        this.mixer = null;
        this.clock = new THREE.Clock();
        this.playAction = null;
        this.play = true;
        this.webxr = null;
        this.handHint = null;
        this.center = null;
        this.resizeObserver = null;
        this.renderer2D = null;
        this.hotSpots = null;
        this.intersects = [];
        this.clickableParts = [];
        this.originalMaterials = new Map();
        this.effectComposer = null;
        this.outlinePass = null;
        this.tooltips = new Map();

        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();

        this.aiAnimationIndex = null;
        this.partsAnimationReady = false;
        this.initCameraPosition = new THREE.Vector3();
        this.pointerDownTime = 0;

        this.init();
    }

    async init() {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: this.canvas,
        });

        this.camera = new THREE.PerspectiveCamera(
            35,
            this.canvasContainer.clientWidth / this.canvasContainer.clientHeight,
            0.1,
            10
        );

        this.camera.position.set(0, 0, 2);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.canvasContainer.clientWidth, this.canvasContainer.clientHeight);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1.2;
    
        this.hotSpots = new HotSpots(this.camera, this.scene);

        document.getElementById("lable-overlay")?.remove();

        this.renderer2D = new CSS2DRenderer(this.canvasContainer);
        this.renderer2D.setSize(this.canvasContainer.clientWidth, this.canvasContainer.clientHeight);
        this.renderer2D.domElement.style.position = 'absolute';
        this.renderer2D.domElement.style.top = '0px';
        this.renderer2D.domElement.id = 'lable-overlay';

        this.canvasContainer.appendChild(this.renderer2D.domElement);

        const envMap = await this.hdrLoader.loadAsync("/assets/images/hdri/paul_lobe_haus_2k_bottom.hdr");
        envMap.mapping = THREE.EquirectangularReflectionMapping;
        this.scene.environment = envMap;

        const light = new THREE.DirectionalLight(0xffffff, 0);
        light.position.set(0, 2, 0);
        light.castShadow = true;
        light.shadow.mapSize.width = 2048;
        light.shadow.mapSize.height = 2048;
        light.shadow.camera.near = 0.1;
        light.shadow.camera.far = 2;
        light.shadow.camera.left = -5;
        light.shadow.camera.right = 5;
        light.shadow.camera.top = 5;
        light.shadow.camera.bottom = -5;
        light.shadow.radius = 6;
        light.shadow.blurSamples = 10;
        light.shadow.bias = -0.0005;

        this.scene.add(light);
        const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
        this.scene.add(hemisphereLight);

        this.controls = new OrbitControls(this.camera, this.renderer2D.domElement);
        this.controls.enableDamping = true;

        this.handHint = new HandHint(this.camera);
        this.renderer2D.domElement.addEventListener("pointerdown", () => {
            if (this.handHint) {
                this.handHint.stop();
            }
        });

        this.scene.add(this.camera);
        this.webxr = new WebXRModule({
            scene: this.arScene,
            camera: this.camera,
            renderer: this.renderer,
        });
        this.webxr.onEnd = () => {
            this.animate();
            this.onWindowResize();
            this.scene.add(this.arScene);
        };

        window.addEventListener("resize", this.onWindowResize.bind(this));

        this.resizeObserver = new ResizeObserver(() => {
            this.onWindowResize();
        });

        this.resizeObserver.observe(this.canvasContainer);

        this.canvasContainer.addEventListener('pointerdown', this.onRaycasterEvent.bind(this));
        this.canvasContainer.addEventListener('mousemove', this.onMouseMove.bind(this));
        this.canvasContainer.addEventListener('dblclick', this.onDoubleClick.bind(this));

        const planeGeometry = new THREE.PlaneGeometry(5, 5);
        const planeMaterial = new THREE.ShadowMaterial({
            opacity: 0.6,
        });


        const ground = new THREE.Mesh(planeGeometry, planeMaterial);
        ground.rotation.x = -Math.PI / 2;
        ground.castShadow = true;
        ground.receiveShadow = true;
        this.scene.add(ground);

        this.arScene.add(this.reference);

        const renderPass = new RenderPass(this.scene, this.camera);
        this.outlinePass = new OutlinePass(new THREE.Vector2(this.canvasContainer.clientWidth, this.canvasContainer.clientHeight), this.scene, this.camera);
        this.outlinePass.edgeStrength = 5;
        this.outlinePass.edgeGlow = 1;
        this.outlinePass.edgeThickness = 0.1;
        this.outlinePass.pulsePeriod = 5;
        this.outlinePass.visibleEdgeColor.set('#95783B');
        this.outlinePass.hiddenEdgeColor.set('#190a05');

        this.effectComposer = new EffectComposer(this.renderer);
        this.effectComposer.addPass(renderPass);
        this.effectComposer.addPass( new OutputPass() );

        this.effectComposer.addPass(this.outlinePass);
    }

    onDoubleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.canvas.focus();
        this.controls.enabled = true;
        this.controls.update();
    }

    loadAssets(appData) {
        this.start();
        this.setLoading("done");
        this.loadHotspots(appData.hotSpots);
        this.load3D(appData.model_file);
    }

    loadHotspots(hotSpots) {
        if (hotSpots?.length > 0) {
            this.hotSpots.init(hotSpots);
        }
    }

    async enterAR() {
        cancelAnimationFrame(this.animationFrame);
        this.playAction.time = 0;
        this.play = false;
        this.mixer.update(0.1);
        this.webxr.startAR();
    }

    pauseAnimation(state) {
        this.play = state;
    }

    isPlaying() {
        return this.play;
    }

    onRaycasterEvent(e) {
        const delta = Date.now() - this.pointerDownTime;
        this.pointerDownTime = Date.now();
        if (delta < 250) {
            const rect = this.canvasContainer.getBoundingClientRect();
            this.mouse.x = (e.clientX - rect.left) / rect.width * 2 - 1;
            this.mouse.y = - (e.clientY - rect.top) / rect.height * 2 + 1;
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.intersects);
            if (intersects[0] && this.modeRef.current === "parts") {
                const object = intersects[0].object.name;
                this.onPartSelect(object);
            }
            this.onDoubleClick(e);
        }
    }

    onMouseMove(e) {
        const rect = this.canvasContainer.getBoundingClientRect();
        this.mouse.x = (e.clientX - rect.left) / rect.width * 2 - 1;
        this.mouse.y = - (e.clientY - rect.top) / rect.height * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);        
        const intersects = this.raycaster.intersectObjects(this.intersects);
        
        // Remove existing tooltips
        this.tooltips.forEach((tooltip) => {
            tooltip.element.remove();
            tooltip.parent.remove(tooltip);
        });
        this.tooltips.clear();
    
        if (intersects.length > 0 && this.modeRef.current === "parts") {
            const intersectedObject = intersects[0].object;
            const selectItem = this.productData.allProducts.find(product => intersectedObject.name.includes(product.upc_number));
    
            if (selectItem) {
                if (!this.tooltips.has(intersectedObject.name)) {
                    const tooltipDiv = document.createElement('div');
                    tooltipDiv.className = 'tooltip';
                    tooltipDiv.textContent = selectItem.name;
                    tooltipDiv.style.color = 'white';
                    tooltipDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.75)';
                    tooltipDiv.style.padding = '5px';
                    tooltipDiv.style.borderRadius = '5px';
                    tooltipDiv.style.fontSize = '12px';
                    tooltipDiv.style.fontWeight = 'bold';
                    tooltipDiv.style.textAlign = 'center';
                    tooltipDiv.style.width = 'fit-content';
                    tooltipDiv.style.maxWidth = '200px';
                    tooltipDiv.style.whiteSpace = 'wrap';
                    tooltipDiv.style.overflow = 'hidden';
                    tooltipDiv.style.textOverflow = 'ellipsis';
                    tooltipDiv.style.border = '1px solid #95783B';
                    tooltipDiv.style.pointerEvents = 'none';
    
                    const tooltipObject = new CSS2DObject(tooltipDiv);
                    intersectedObject.add(tooltipObject);
                    this.tooltips.set(intersectedObject.name, tooltipObject);
                    const tooltip = this.tooltips.get(intersectedObject.name);
                    tooltip.position.copy(intersects[0].point);
                }
            }
        }
    }
    
    
    onWindowResize() {
        this.camera.aspect = this.canvasContainer.clientWidth / this.canvasContainer.clientHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.canvasContainer.clientWidth, this.canvasContainer.clientHeight);
        this.renderer.render(this.scene, this.camera);
        this.renderer2D.setSize(this.canvasContainer.clientWidth, this.canvasContainer.clientHeight);
        if (this.effectComposer) {
            this.effectComposer.setSize(this.canvasContainer.clientWidth, this.canvasContainer.clientHeight);
        }
    }

    updateReference(style) {
        this.reference.updateStyle(style);
    }

    updateMode(mode) {
        if (this.mixer) {
            this.mixer.stopAllAction();
        }

        this.playAnimation(null);
        this.updateReference(null);
        this.play = true;
        switch (mode) {
            case "tutorial":
                if (this.aiAnimationIndex) {
                    this.playAnimation(0);
                } else {
                    console.log("no tutorial animation to play")
                }
                this.hotSpots.toggleVisible(false);
                this.clearHighlights();
                this.reCenter(this.gltf.scene);
                break;
            case "parts":
                this.playAnimation(this.aiAnimationIndex);
                this.hotSpots.toggleVisible(false);
                this.highlightClickableParts();
                this.play = false;
                this.reCenter(this.gltf.scene);
                break;
            case "size":
                this.updateReference("all");
                this.reCenter(this.arScene, 4);
                this.hotSpots.toggleVisible(false);
                this.clearHighlights();
                break;
            // case "relative":
            //     this.updateReference("person")
            //     // playAnimation
            //     break;
            default:
                this.reCenter(this.gltf.scene);
                this.hotSpots.toggleVisible(true);
                this.clearHighlights();
                break;
        }
    }

    highlightClickableParts() {
        this.gltf.scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                const selectItem = this.productData.allProducts.find(product => child.name.includes(product.upc_number));
                if (selectItem) {
                    this.clickableParts.push(child);
                }
            }
        });
        if (this.outlinePass) {
            this.outlinePass.selectedObjects = this.clickableParts;
        }
    }

    clearHighlights() {
        if (this.outlinePass && this.clickableParts.length > 0) {
            this.outlinePass.selectedObjects = [];
            this.clickableParts = [];
        }
    }
    
    onPartsAnimationReady() {
        if (this.onReadyPartsAnimationCallback) {
            this.onReadyPartsAnimationCallback();
        }
    }

    onReadyPartsAnimation(callback) {
        this.onReadyPartsAnimationCallback = callback;
    }
    

    load3D(url) {
        this.partsAnimationReady = false;

        this.gltfLoader.load(
            url,
            (gltf) => {
                gltf.scene.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.castShadow = true;
                        this.intersects.push(child);
                    }
                });

                this.gltf = gltf;
                this.arScene.add(gltf.scene);

                this.mixer = new THREE.AnimationMixer(gltf.scene);
                const box = new THREE.Box3();
                box.setFromObject(gltf.scene);
                const center = box.getCenter(new THREE.Vector3());
                this.center = center;

                gltf.scene.position.y = -box.min.y;
                if (box) {
                    const box2 = new THREE.Box3();
                    box2.setFromObject(gltf.scene);
                    const center2 = box2.getCenter(new THREE.Vector3());

                    if (!this.controls) {
                        this.controls = new OrbitControls(this.camera, this.renderer2D.domElement);
                        this.controls.enableDamping = true;
                    }

                    this.controls.target.copy(center2);
                    this.camera.position.set(0, box2.max.y, box2.max.z + 2);

                    this.reference.build(box2);
                    this.controls.maxDistance = box2.max.z + 9;
                    this.controls.minDistance = box2.max.z + 0.8;
                }
                const aiAnimationClip = GenerateBlowOutAnimation(gltf);
                gltf.animations.push(aiAnimationClip);
                this.aiAnimationIndex = gltf.animations.length - 1;

                // console.log({ aiAnimationClip })
                this.partsAnimationReady = true;
                this.onPartsAnimationReady();
                this.onWindowResize();
                this.hotSpots.toggleVisible(true);
                this.setLoading("done");
                this.playAnimation(this.aiAnimationIndex);
            },
            (xhr) => {
                const loadingAmount = Math.floor((xhr.loaded / xhr.total) * 100);
                this.setLoading(loadingAmount);
            },
            (error) => {
                console.error("An error happened when loading 3d models", error);
            }
        );



    }

    reCenter(object, offsetZ) {
        const box = new THREE.Box3();
        box.setFromObject(object);
        const center = box.getCenter(new THREE.Vector3());
        this.controls.enabled = false;
        gsap.to(this.controls.target, {
            x: center.x,
            y: center.y,
            z: center.z,
            duration: 1,
            onUpdate: () => {
                this.controls.update();
            },
            onComplete: () => {
                this.controls.enabled = true;
            }
        });
        gsap.to(this.camera.position, {
            x: 0,
            y: box.max.y,
            z: offsetZ ? box.max.z + offsetZ : box.max.z + 2,
            duration: 1,
            onUpdate: () => {
                this.camera.lookAt(center);
            }
        });
    }

    updateAnimationTime(time) {
        if (time > 99) {
            time = 99;
        }
        if (!this.playAction && !this.mixer || !this?.playAction?._clip) return;
        this.play = false;
        const currentTime = this.playAction._clip.duration * time * 0.01;
        this.playAction.time = currentTime;
        this.mixer.update(0.001);
    }
    updateTutorialAnimationTime(time) {
        if (time > 99) {
            time = 99;
        }
        if (!this.playAction && !this.mixer || !this?.playAction?._clip) return;
        this.play = false;
        const currentTime = this.playAction._clip.duration * time * 0.01
        this.playAction.time = currentTime;
        this.mixer.update(0.001);
        this.play = true;
    }

    playAnimation(index) {
        this.playAction && this.playAction.stop();

        if (typeof index === "number") {
            this.playAction = this.mixer.clipAction(this.gltf.animations[index]);
            this.playAction.play();

        } else {
            this.playAction && this.playAction.stop();
        }
    }

    getCurrentAnimationTime() {
        if (!this.playAction) {
            return 0;
        }
        if (!this.playAction._clip) {
            return 0;
        }
        const currentTime = (this.playAction.time / this.playAction._clip.duration) * 100;
        return currentTime || 0;
    }

    animate() {
        this.animationFrame = requestAnimationFrame(this.animate.bind(this));
        this.controls && this.controls.update();

        const delta = this.clock.getDelta();
        this.mixer && this.play && this.mixer.update(delta);
        this.camera.updateMatrixWorld();
        this.renderer.render(this.scene, this.camera);
        if (this.effectComposer) {
            this.effectComposer.render();
        }
        this.renderer2D.render(this.scene, this.camera);
    }
    stop() {
        cancelAnimationFrame(this.animationFrame);
        this.hotSpots.stop();
        this.clear();
    }
    clear() {
        if (this.gltf?.scene) {
            this.arScene.remove(this.gltf.scene);
        }
        this.scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                const keys = Object.keys(child.material);
                keys.forEach(key => {
                    // console.log(child.material[key])
                    if (child.material[key]?.isTexture) {
                        child.material[key].dispose();
                    }
                })
                child.material.dispose();

            }
        });
        this.renderer && this.renderer.dispose();
    }
    async start() {
        this.animate();
    }
}

export default ThreeJSModule;