
import React, { useContext } from "react";
import { Box, Typography, Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { CheckoutContext } from "../CheckoutModal";



function OtherProducts() {

    const { allProducts, checkoutProducts, addProductHandler } = useContext(CheckoutContext);
    const otherProducts = allProducts.filter(product => !checkoutProducts.find(cp => cp.name === product.name))

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "10px",
            }}
        >

            {
                otherProducts.map((product, index) => (
                    product &&
                    <AddCard
                        key={index}
                        onAdd={() => addProductHandler(product)} product={product} />
                ))
            }

        </Box>
    );
}

export default OtherProducts;




const AddCard = ({ onAdd, product }) => {

    const { setPreviewProduct } = useContext(CheckoutContext);


    return (
        <Box
            sx={{
                border: "1px solid rgba(255, 255, 255, 0.46);",
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
                borderRadius: "7px",
                gap: "20px",
                background: "#141313",
            }}
        >

            <Box
                onClick={() => { setPreviewProduct(product) }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: "10px",
                    cursor: "pointer"
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf: "flex-start",
                        gap: "10px"
                    }}
                >
                    <Box
                        component='img'
                        src={product.default_image}
                        sx={{
                            width: "48px"
                        }}
                    />
                    <Box>
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                fontSize: "0.8rem"
                            }}
                        > {product.name}</Typography>
                        <Typography
                            sx={{
                                width: "100px",
                                fontSize: "0.8rem"
                            }}
                        > {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: product.price_details.currency,
                        }).format(product.price_details.retail_price)}</Typography>

                    </Box>

                </Box>
                <Typography
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        fontSize: "0.65rem"
                    }}

                >{product.description.replace(/<p>/g, "").replace(/<\/p>/g, "")}</Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: "5px",
                }}
            >
                <Button
                    onClick={onAdd}
                    size="small"
                    sx={{
                        color: "white",
                        fontSize: "0.6rem"
                    }}
                    startIcon={<AddIcon />} variant="text">Add</Button>
            </Box>


        </Box>
    )

}